import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

import { Button, Loading } from "components";

import "./index.scss";

const DEFAULT_STATE_KEY = "completed";
const STATES: any = {
  completed: {
    title: "Votre dossier a bien été pris en compte !",
    content: (
      <>
        Le paiement a bien été effectué. Celui-ci prendra effet dès validation
        auprès du club.
        <br />
        Merci d'avoir renouvelé votre licence
      </>
    ),
  },
  error: {
    title: "Une erreur est survenue lors du paiement...",
    content: <>Le paiement n'a pas pu aboutir.</>,
  },
  canceled: {
    title: "Vous avez annulé le paiement !",
    content: (
      <>
        Le paiement a été annulé. De ce fait, aucun prélèvement ne sera
        effectué.
      </>
    ),
  },
  virement: {
    title: "Votre dossier a bien été pris en compte !",
    content: (
      <>
        Votre dossier inscription est <b>en attente de validation</b> par le
        club.
        <br />
        Merci d'adresser votre virement en spécifiant le <b>nom</b> et le{" "}
        <b>prénom</b> de la licence que vous règlez.
        <br />
        Assurez-vous que ce dernier soit bien en{" "}
        <u>capacité de prendre de nouvelles adhésions</u>.
      </>
    ),
  },
  autres: {
    title: "Votre dossier a bien été pris en compte !",
    content: (
      <>
        Merci de vous rapprocher des dirigeants du club pour fournir vos
        réglèments afin que votre adhésion soit validée.
      </>
    ),
  },
};

const Step07: React.FC = () => {
  const [currentStateArgs, setCurrentStateArgs] = useState<any>();
  const { completedState = DEFAULT_STATE_KEY } = useParams<{
    completedState: string;
  }>();

  useEffect(() => {
    if (!Object.keys(STATES).includes(completedState)) {
      setCurrentStateArgs(STATES.error);
      return;
    }

    setCurrentStateArgs(STATES[completedState]);
  }, [completedState]);

  const handleOnRedirect = () => {
    const w: any = window as any;
    w.location = process.env.REACT_APP_MYFFBAD_URL + "recherche/les-tops";
  };

  if (!currentStateArgs) {
    return <Loading />;
  }

  return (
    <div className="completed-container">
      <h2 className="completed-title">{currentStateArgs.title}</h2>

      <p>{currentStateArgs.content}</p>

      <img src="/assets/images/renewal_payment.png" alt="paiement" />
      <Button onClick={handleOnRedirect} title="Accéder à mon espace Licenciés">
        <FontAwesomeIcon icon={faShare as any} />
        Accéder à mon espace Licenciés
      </Button>
    </div>
  );
};

export default Step07;
